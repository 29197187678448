<template>
    <button
        type="button"
        class="flex items-center justify-center hover:bg-sky-100 rounded-lg w-9 h-9"
        v-tippy
        :content="label"
        @click="doCopy"
    >
        <SvgIcon :icon="icon" size="sm"/>
    </button>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import $events from "../../event-bus";
import {copyToClipboard} from "../../utils";

export default defineComponent({
    props: {
        icon: {
            type: String,
            default: "clipboard",
        },
        label: {
            type: String,
            default: "Copy",
        },
        value: String,
    },
    methods: {
        async doCopy() {
            await copyToClipboard(this.value ?? "");
            $events.$emit("toastNotification", {
                status: "success",
                heading: "Copied to clipboard!",
            });
        },
    },
});
</script>
