<template>
    <div class="flex items-center">
        <ActionLink
            :url="url"
            icon="download"
            v-if="exportLink"
            @action-button-click="exportData"
        >
            Export
        </ActionLink>
        <ActionButton
            v-if="!exportLink"
            :class="{
                'rounded-r-none': shareable,
            }"
            dusk="export-button"
            icon="download"
            @action-button-click="exportData"
        >
            Export
        </ActionButton>
        <CopyAction
            v-if="shareable"
            icon="share"
            class="border border-blue-500 rounded-l-none border-l-0 text-blue-600"
            label="Copy shareable link"
            :value="shareUrl"
        />
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: "DataExport",
    props: {
        exportLink: Boolean,
        filename: String,
        shareable: Boolean,
        url: {
            type: String,
            required: true,
        },
        shareUrl: {
            type: String,
            required: false,
        },
    },
    methods: {
        exportData() {
            // @ts-ignore
            this.$events.$emit("toastNotification", {
                heading: "Exporting data. Please wait...",
            });

            if (!this.exportLink) {
                fetch(this.url)
                    .then(res => res.json())
                    .then((json) => {
                        this.$events.$emit("toastNotification", {
                            action: "saved",
                            heading: "Data export succeeded! You'll receive an email shortly with a link to the export. This may take 2-3 minutes.",
                            status: "success",
                        });
                    })
                    .catch((error) => {
                        // @ts-ignore
                        this.$events.$emit("toastNotification", {
                            action: "error",
                            heading: "Data export failed!",
                            status: "error",
                        });
                    });
            }
        },
    },
});
</script>
